const course = (
  state = {
    courseState: null,
    selectedSection: null,
    selectedChapter: null,
    selectedTaskGroup: null,
    sectionToVerify: null,
    sectionToResultView: null
  },
  action
) => {
  switch (action.type) {
    case "SET_FULL_COURSE_STATE":
      return {
        ...state,
        courseState: action.data,
      };
    case "SET_SELECTED_SECTION":
      return {
        ...state,
        selectedSection: action.data,
        selectedChapter: null,
        selectedTaskGroup: null,
      };
    case "SET_SELECTED_CHAPTER":
      return {
        ...state,
        selectedChapter: action.data,
        selectedTaskGroup: null,
      };
    case "SET_SELECTED_TASK_GROUP":
      return {
        ...state,
        selectedTaskGroup: action.data,
      };
    case "SET_SECTION_TO_VERIFY":
      return {
        ...state,
        sectionToVerify: action.data,
      };
    case "SET_SECTION_TO_RESULT_VIEW":
      return {
        ...state,
        sectionToResultView: action.data,
      };
    case "RESET_NAVIGATION":
      return {
        ...state,
        selectedSection: null,
        selectedChapter: null,
        selectedTaskGroup: null,
        sectionToVerify: null,
        sectionToResultView: null,
      };
    default:
      return state;
  }
};

export default course;
