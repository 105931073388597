import React, { useState, useCallback, useMemo } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withScorm } from "react-scorm-provider";
import { getMode as getScormMode } from "scorm/api";

import "./ChapterIntersection.scss";

import {
  setSelectedTaskGroup,
  setSectionToVerify,
  toggleChapterNav,
  setFullCourseState,
  notifyAccessStatusChange
} from "actions";

import { ENTITY_STATUSES, IS_SCORM } from "consts";

import {
  postPlaceholderTaskAnswered,
  postChapterAnswered,
  postCourseCompleted
} from "tracker-api/statements";
import { getAccessStatus, getCourseState } from "tracker-api/sessions";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCurrentCourse from "hooks/useCurrentCourse";
import useCurrentSection from "hooks/useCurrentSection";
import useCurrentChapter from "hooks/useCurrentChapter";
import useNextTaskGroup from "hooks/useNextTaskGroup";
import useNextChapter from "hooks/useNextChapter";
import useWindowSize from "hooks/useWindowSize";
import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import Error from "components/Error/Error";
import Loading from "components/Loading/Loading";
import ChapterRedirect from "components/ChapterRedirect/ChapterRedirect";
import { ReactComponent as EyeIcon } from "images/icons/eye.svg";
import { ReactComponent as MovenextIcon } from "images/icons/movenext.svg";
import { ReactComponent as TodoIcon } from "images/icons/todo.svg";

const IntersectionIcon = () => {
  return (
    <div className="Chapter-intersection__icon">
      <TodoIcon />
    </div>
  );
};

function ChapterIntersection(props) {
  const { t } = useTranslation();
  const sectionObj = useCurrentSection();
  const courseObj = useCurrentCourse();
  const chapterObj = useCurrentChapter();
  const nextChapterObj = useNextChapter();
  const nextTaskGroupObj = useNextTaskGroup();
  const eduadminId = useCurrentEduadminId();
  const user = useSelector(getUserWithAccessStatus);
  const dispatch = useDispatch();
  const { isDesktop } = useWindowSize();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const selectedTaskGroup = useSelector(
    state => state.course.selectedTaskGroup
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sco = useMemo(() => props.sco, []);

  const { isInOverlay } = props;

  const goToNextChapter = useCallback(
    isEmptyChapter => {
      const _refresh = async () => {
        setIsSubmitLoading(true);

        // TODO proper error handling?

        if (isEmptyChapter) {
          // Only for chapters that don'nt have any tasks
          await postPlaceholderTaskAnswered(
            chapterObj.data.blbChapterId,
            eduadminId,
            user,
            sco
          );

          // Send chapter answered event
          // to tracker.
          // It's an informative event and is not crucial
          // for progress in tracker.
          await postChapterAnswered(
            chapterObj.data.blbChapterId,
            eduadminId,
            user,
            sco
          );
        }

        const getRes = await getCourseState(eduadminId, user, true, sco);

        const allSectionsSuccess =
          getRes.sections.filter(section => {
            return !section.success;
          }).length === 0;

        // IMPORTANT: this triggers the grade in eduadmin. Will
        // only happen here if it's the last chapter and it
        // has no tasks.
        // TODO make a retry thing?
        // TODO create combined listener hook
        // in Course.js that handles this, started statements etc
        if (allSectionsSuccess && isEmptyChapter) {
          await postCourseCompleted(
            courseObj.data.course,
            eduadminId,
            user,
            sco
          );
          // We don't need to reload state here,
          // because neither tracker or scorm data
          // changes from this call

          // Tell Course.js that it's time
          // to refresh access status, since
          // tracker will set hasActiveCertificate/hasCompletedCourse
          // to true
          dispatch(notifyAccessStatusChange())

          
        }
        dispatch(setFullCourseState(getRes));
        setIsSubmitLoading(false);
        setShouldRedirect(true);
      };
      _refresh();
    },
    [
      chapterObj,
      courseObj,
      setIsSubmitLoading,
      setShouldRedirect,
      dispatch,
      sco,
      eduadminId,
      user
    ]
  );

  const isEmptyChapter =
    chapterObj.data &&
    _.isEmpty(chapterObj.data.blbGroupChapterTasks.blbChapterTasks);

  const nextTaskId = nextTaskGroupObj.data
    ? nextTaskGroupObj.data.blbChapterTaskGroupId
    : null;

  const nextChapterId =
    nextChapterObj.data && nextChapterObj.data.blbChapterId
      ? nextChapterObj.data.blbChapterId
      : null;

  const isChapterCompleted =
    chapterObj.data &&
    chapterObj.data.progress.status === ENTITY_STATUSES.COMPLETE;
  const isSectionCompleted =
    sectionObj.data &&
    sectionObj.data.progress.status === ENTITY_STATUSES.COMPLETE;
  const isSectionSuccess =
    sectionObj.data &&
    sectionObj.data.progress.status === ENTITY_STATUSES.VERIFIED_SUCCESS;

  if (shouldRedirect) {
    // Force redirect back to section select page if it's a chapter
    // without tasks, and the section is success, which it might be
    // before verification since chapters without tasks verify
    // instantly. Usually, useNextChapter will just return the first
    // chapter of an already verified section.
    return (
      <ChapterRedirect
        forceSectionSelect={isEmptyChapter && isSectionSuccess}
      />
    );
  }

  if (
    nextTaskGroupObj.loading ||
    chapterObj.loading ||
    nextChapterObj.loading ||
    sectionObj.loading
  )
    return <Loading />;
  if (nextTaskGroupObj.error) return <Error error={nextTaskGroupObj.error} />;
  if (chapterObj.error) return <Error error={chapterObj.error} />;
  if (nextChapterObj.error) return <Error error={nextChapterObj.error} />;
  if (sectionObj.error) return <Error error={sectionObj.error} />;

  const cls =
    "Chapter-intersection" +
    (isInOverlay ? " Chapter-intersection--isInOverlay" : "") +
    (isSectionSuccess ? " Chapter-intersection--isSectionSuccess" : "") +
    (isChapterCompleted ? " Chapter-intersection--isChapterCompleted" : "");
  const innerCls = "Chapter-intersection__inner";
  const txtCls = "Chapter-intersection__text";
  const btnCls = "Chapter-intersection__btn";

  // If we're not in overlay, just keep the initial state to
  // not show next state while overlay enter animation is running.
  const shouldShowInitialStateAnyway = !isInOverlay && !!selectedTaskGroup;

  // If we're in SCORM and not running normal mode,
  // user should not be able to view or answer tasks.
  const tasksNotAllowed = IS_SCORM && sco && getScormMode(sco) !== "normal";

  return (
    <div className={cls}>
      {nextTaskId || shouldShowInitialStateAnyway ? (
        <div className={innerCls}>
          <IntersectionIcon />
          <p className={txtCls}>{t("TIME_TO_GET_STARTED")}</p>
          <button
            type="button"
            disabled={tasksNotAllowed}
            className={btnCls}
            onClick={() => dispatch(setSelectedTaskGroup(nextTaskId))}
          >
            {t("START")}
          </button>
        </div>
      ) : nextChapterId && !isSectionSuccess && !isEmptyChapter ? (
        <div className={innerCls}>
          <IntersectionIcon />
          <p className={txtCls}>
            <span>
              {t("ALL_TASKS_ARE_COMPLETED_FOR_X_Y", {
                chapterNumber: chapterObj.data.chapterNumber,
                chapterTitle: chapterObj.data.titleRendered
              })}{" "}
            </span>
            <span>
              {t("ARE_YOU_READY_TO_TAKE_ON_X_Y", {
                chapterNumber: nextChapterObj.data.chapterNumber,
                chapterTitle: nextChapterObj.data.titleRendered
              })}
            </span>
          </p>

          <button
            type="button"
            className={btnCls}
            disabled={tasksNotAllowed}
            onClick={() => {
              goToNextChapter(false);
            }}
          >
            {t("CONTINUE_WITH_NEXT_CHAPTER")}
            <div className={btnCls + "-icon"}>
              <MovenextIcon />
            </div>
          </button>
        </div>
      ) : isEmptyChapter && !isSectionCompleted && !isSectionSuccess ? (
        <div className={innerCls}>
          {/* TODO */}
          <IntersectionIcon />
          <p className={txtCls}>
            <span>
              {nextChapterId
                ? t(
                    "DID_YOU_PARTAKE_OF_ALL_FACTS_ON_X_X_AND_READY_TO_TAKE_ON_Y_Y",
                    {
                      currentChapterNumber: chapterObj.data.chapterNumber,
                      currentChapterTitle: chapterObj.data.titleRendered,
                      nextChapterNumber: nextChapterObj.data.chapterNumber,
                      nextChapterTitle: nextChapterObj.data.titleRendered
                    }
                  )
                : t("DID_YOU_PARTAKE_OF_ALL_FACTS_ON_X_X", {
                    currentChapterNumber: chapterObj.data.chapterNumber,
                    currentChapterTitle: chapterObj.data.titleRendered
                  })}
            </span>
          </p>

          <button
            type="button"
            className={btnCls}
            disabled={tasksNotAllowed}
            onClick={() => {
              goToNextChapter(true);
            }}
          >
            {nextChapterId ? t("CONTINUE_WITH_NEXT_CHAPTER") : t("DONE")}
            <div className={btnCls + "-icon"}>
              <MovenextIcon />
            </div>
          </button>
        </div>
      ) : isSectionCompleted ? (
        <div className={innerCls}>
          <button
            type="button"
            className={btnCls}
            disabled={tasksNotAllowed}
            onClick={() =>
              dispatch(setSectionToVerify(sectionObj.data.blbCourseSectionId))
            }
          >
            {t("VERIFY_X_CHAPTERS", {
              chaptersCount: sectionObj.data.blbCourseSectionChapterPosts.length
            })}
          </button>
        </div>
      ) : isSectionSuccess ? (
        <div className={innerCls}>
          <IntersectionIcon />
          <p className={txtCls}>
            {t("ALL_TASKS_ARE_COMPLETED_FOR_X_Y", {
              chapterNumber: chapterObj.data.chapterNumber,
              chapterTitle: chapterObj.data.titleRendered
            })}
          </p>
          {!isDesktop ? (
            <button
              type="button"
              className={btnCls}
              onClick={() => dispatch(toggleChapterNav())}
            >
              {t("SHOW_SECTION_MENU")}
              <div className={btnCls + "-icon"}>
                <EyeIcon />
              </div>
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

const Comp = IS_SCORM ? withScorm()(ChapterIntersection) : ChapterIntersection;

export default Comp;
