import React from "react";

import "./TaskInteractionText.scss";

import TaskOption from "components/TaskOption/TaskOption";

import { IS_DEBUG } from "consts";

import isReflectiveTask from "utils/isReflectiveTask";
import taskHasMultipleRightAnswers from "utils/taskHasMultipleRightAnswers";
import getTaskSelectFn from "utils/getTaskSelectFn";
import getTaskSelectedValuesIds from "utils/getTaskSelectedValuesIds";

function TaskInteractionText(props) {
  const { taskData, isInFacts } = props;
  const options = taskData.blbGroupTaskOptions.blbTaskOptionTypeTextOptions;

  // If it's a reflective task, or if multiple right answers exists,
  // we assume it's a multichoice task.
  // TODO when the task is used in facts section, don't allow multiselect
  // for now. We might want to change this later.
  const isMultiAnswerTask =
    (isReflectiveTask(taskData) || taskHasMultipleRightAnswers(taskData)) &&
    !isInFacts;

  const allowDeselectOfSingleAnswer = isInFacts;
  const selectFn = getTaskSelectFn(
    props.selectedValues,
    props.selectFn,
    allowDeselectOfSingleAnswer,
    isMultiAnswerTask
  );

  const selectedValuesIds = getTaskSelectedValuesIds(props.selectedValues);

  return (
    <div className="Task-interaction-text">
      <ul>
        {options.map((option, i) => {
          const isLast = i === options.length - 1;
          const value = option.blbTaskTypeTextOptionId;
          const label = option.blbTaskTypeTextOptionLabel;
          const isCorrect = !!option.blbTaskTypeTextOptionCorrect;
          const isSelected = selectedValuesIds.includes(value);
          const cls =
            "Task-interaction-text__item" +
            (isLast ? " Task-interaction-text__item--last" : "");
          return (
            <li key={value + i} className={cls}>
              <TaskOption
                value={value}
                label={label}
                selectFn={selectFn}
                allowDeselectOfSingleAnswer={allowDeselectOfSingleAnswer}
                isSelected={isSelected}
                isMultiple={isMultiAnswerTask}
                dc={isCorrect && IS_DEBUG}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TaskInteractionText;
