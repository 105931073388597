import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18n";
import { useParams } from "react-router-dom";
import { useQuery as useApolloQuery } from "@apollo/client";

import { GET_COURSE } from "apollo/queries";
import { IS_BYPASS_SSO, IS_SCORM } from "consts";
import {
  setLanguageWasSetFromCourse,
  setTheme,
  setScormCourseDataCache,
  setScormCourseDataLoading,
  setCurrentLanguage
} from "actions";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import getCurrentLangSlug from "utils/getCurrentLangSlug";
import sectionHasTasks from "utils/sectionHasTasks";
import debugLog from "utils/debugLog";

function useCurrentCourse() {
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(getUserWithAccessStatus);
  const scormCourseDataCache = useSelector(
    state => state.scorm.scormCourseDataCache
  );
  const scormCourseDataLoading = useSelector(
    state => state.scorm.scormCourseDataLoading
  );

  const [scormError, setScormError] = useState(null);
  const [didScromQueryOnce, setDidScromQueryOnce] = useState(false);

  const languageWasSetFromCourse = useSelector(
    state => state.layout.languageWasSetFromCourse
  );
  const didSetTheme = useSelector(state => state.layout.didSetTheme);

  const apolloQueryObj = useApolloQuery(GET_COURSE, {
    variables: { courseSlug },
    skip: (!user && !IS_BYPASS_SSO) || IS_SCORM // If no one is logged in, don't even fetch the course
  });

  const data = IS_SCORM ? scormCourseDataCache : apolloQueryObj.data;
  const loading = IS_SCORM ? scormCourseDataLoading : apolloQueryObj.loading;
  const error = IS_SCORM ? scormError : apolloQueryObj.error;

  useEffect(() => {
    const getScormCourseData = async () => {
      dispatch(setScormCourseDataLoading(true));
      setDidScromQueryOnce(true);
      const res = await fetch("courseContent.json", {
        method: "GET"
      })
        .then(_res => {
          return _res.json();
        })
        .then(json => {
          dispatch(setScormCourseDataCache(json));
          dispatch(setScormCourseDataLoading(false));
        })
        .catch(err => {
          console.error(err);
          setScormError(err);
          dispatch(setScormCourseDataLoading(false));
        });
      return res;
    };

    if (IS_SCORM && !data && !loading && !error && !didScromQueryOnce) {
      getScormCourseData();
    }
  }, [dispatch, data, loading, didScromQueryOnce, error]);

  // Get and set the theme for this course
  useEffect(() => {
    if (
      data &&
      data.course &&
      data.course.blbGroupCourseTheme &&
      data.course.blbGroupCourseTheme.blbCourseTheme &&
      !didSetTheme
    ) {
      const themeToSet = data.course.blbGroupCourseTheme.blbCourseTheme;
      debugLog("Setting theme", themeToSet);
      dispatch(setTheme(themeToSet));
    }
  }, [data, dispatch, didSetTheme]);

  // Get and set the lang for this course
  useEffect(() => {
    if (data) {
      let courseLanguage = getCurrentLangSlug(data);

      if (!languageWasSetFromCourse) {
        if (!courseLanguage) {
          console.warn(
            "There is no language set for this course! Defaulting to sv."
          );
          courseLanguage = "sv";
        }
        i18n.changeLanguage(courseLanguage, (err, t) => {
          if (err) return debugLog("Failed setting language", courseLanguage);
          return debugLog("Set language to", courseLanguage);
        });
        dispatch(setLanguageWasSetFromCourse());
      }
      dispatch(setCurrentLanguage(courseLanguage));
    }
  }, [data, languageWasSetFromCourse, dispatch]);

  if (loading) debugLog("Loading course");
  if (error) debugLog("Error loading course");

  // debugLog("Apollo query networkStatus", networkStatus);

  const courseHasTasks =
    data && data.course
      ? data.course.blbGroupCourseSections.blbCourseSections.filter(section =>
          sectionHasTasks(section)
        ).length > 0
      : false;

  return {
    loading,
    error,
    data,
    courseHasTasks
  };
}

export default useCurrentCourse;
