import debugLog from "utils/debugLog";
import { OBJECTS, VERBS } from "tracker-api/types";

import * as Mutations from "./mutations";

const KEY = window.SCORM_COURSE_SLUG
  ? `scormModeStore_${window.SCORM_COURSE_SLUG}`
  : "scormModeStore";

const { LZUTF8 } = window;

const fallbackStorage = {};

function compress(uncompressedInput) {
  return new Promise((resolve, reject) => {
    LZUTF8.compressAsync(
      uncompressedInput,
      { outputEncoding: "Base64", useWebWorker: false },
      function (result, error) {
        if (error === undefined) {
          resolve(result);
        } else {
          console.log("Compression error: " + error.message);
          reject();
        }
      }
    );
  });
}
function decompress(compressedInput) {
  return new Promise((resolve, reject) => {
    LZUTF8.decompressAsync(
      compressedInput,
      {
        inputEncoding: "Base64",
        outputEncoding: "String",
        useWebWorker: false
      },
      function (result, error) {
        if (error === undefined) {
          resolve(result);
        } else {
          console.log("Decompression error: " + error.message);
          reject();
        }
      }
    );
  });
}

function canUseCache() {
  const test = "sstest";
  try {
    window.sessionStorage.setItem(test, test);
    window.sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
function getCachedState() {
  return canUseCache()
    ? window.sessionStorage.getItem(KEY)
    : fallbackStorage[KEY];
}
function setCachedState(compressedInput) {
  return canUseCache()
    ? window.sessionStorage.setItem(KEY, compressedInput)
    : (fallbackStorage[KEY] = compressedInput);
}
function flushCachedState() {
  return canUseCache()
    ? window.sessionStorage.removeItem(KEY)
    : delete fallbackStorage[KEY];
}

export function is2004(sco) {
  return sco.scormVersion === "2004";
}

export function getCredit(sco) {
  if (is2004(sco)) {
    return sco.get("cmi.credit");
  }

  // 1.2
  return sco.get("cmi.core.credit");
}

export function getMode(sco) {
  if (is2004(sco)) {
    return sco.get("cmi.mode");
  }

  // 1.2
  return sco.get("cmi.core.lesson_mode");
}

export function completeCourse(sco) {
  if (getMode(sco) === "normal" && getCredit(sco) === "credit")
    sco.setScore({ value: 100, status: "completed" });
}

export async function getCourseState(sco, forceNoCache = false) {
  let compressedData = getCachedState();
  let freshDataFromLMS;
  if (forceNoCache || !compressedData) {
    flushCachedState();
    const scormState = await sco.getSuspendData();
    if (!scormState[KEY]) {
      debugLog("No suspend data found");
      return {};
    }
    freshDataFromLMS = scormState[KEY];
  }

  compressedData = freshDataFromLMS ? freshDataFromLMS : compressedData;
  setCachedState(compressedData);

  const decompressed = await decompress(compressedData);
  const parsed = JSON.parse(decompressed);

  const info = `${compressedData.length} compressed, ${decompressed.length} decompressed`;
  if (freshDataFromLMS) {
    debugLog("Got suspend data", parsed, info);
  } else {
    debugLog("Got cached data", parsed, info);
  }

  return parsed;
}
export async function setCourseState(input, sco) {
  const isInitializedMutation =
    input.object.type === OBJECTS.COURSE && input.verb === VERBS.INITIALIZED;

  let newState;
  let oldState = await getCourseState(sco, isInitializedMutation);

  let logId;
  let phoneHome = false;
  if (isInitializedMutation) {
    // Course initialized
    newState = Mutations.getCourseInitialized(input, oldState, is2004(sco));
    logId = "Course initialized";
    phoneHome = true;
  } /* else if (
    input.object.type === OBJECTS.SECTION &&
    input.verb === VERBS.STARTED
  ) {
    // Section started
    newState = Mutations.getSectionStarted(input, oldState);
    logId = "Section started";
  } */ else if (
    input.object.type === OBJECTS.SECTION &&
    input.verb === VERBS.ANSWERED
  ) {
    // Section started
    newState = Mutations.getSectionAnswered(input, oldState);
    logId = "Section answered";
    phoneHome = true;
  } else if (
    input.object.type === OBJECTS.SECTION &&
    input.verb === VERBS.RESTARTED
  ) {
    // Section restarted
    newState = Mutations.getSectionRestarted(input, oldState);
    logId = "Section restarted";
  } else if (
    input.object.type === OBJECTS.CHAPTER &&
    input.verb === VERBS.ANSWERED
  ) {
    // Chapter answered
    newState = Mutations.getChapterAnswered(input, oldState);
    logId = "Chapter answered";
    phoneHome = true;
  } else if (
    input.object.type === OBJECTS.TASK &&
    input.verb === VERBS.STARTED
  ) {
    // Task started
    newState = Mutations.getTaskStarted(input, oldState, is2004(sco));
    logId = "Task started";
  } else if (
    input.object.type === OBJECTS.TASK &&
    input.verb === VERBS.ANSWERED
  ) {
    // Task answered
    newState = Mutations.getTaskAnswered(input, oldState, is2004(sco));
    logId = "Task answered";
    phoneHome = true;
  } else if (
    input.object.type === OBJECTS.SECTION &&
    input.verb === VERBS.VERIFIED
  ) {
    // Section verified
    newState = Mutations.getSectionVerified(input, oldState);
    logId = "Section verified";
    phoneHome = true;
  } else if (
    input.object.type === OBJECTS.COURSE &&
    input.verb === VERBS.COMPLETED
  ) {
    completeCourse(sco);
    newState = oldState;
    logId = "Complete course";
  } else {
    newState = oldState;
    logId = "noop";
  }

  const json = JSON.stringify(newState);
  const compressedJson = await compress(json);

  setCachedState(compressedJson);
  if (phoneHome) {
    await sco.setSuspendData(KEY, compressedJson);
    debugLog(`Set new cache and suspend data '${logId}'`, newState);
  } else {
    debugLog(`Set new cache only '${logId}'`, newState);
  }

  return newState;
}
