import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

// TODO probably and alternative endpoint
import { getCourseState } from "tracker-api/analytics";

import SummaryHeader from "components/SummaryHeader/SummaryHeader";
import ParticipantCourseSummarySection from "components/ParticipantCourseSummarySection/ParticipantCourseSummarySection";

import "./ParticipantCourseSummary.scss";

import {
  searchParticipantByPn,
  searchParticipantByEmail
} from "tracker-api/analytics";

import { IS_BYPASS_SSO } from "consts";

import LoginBridge from "components/LoginBridge/LoginBridge";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

function ParticipantCourseSummary() {
  const { t } = useTranslation();
  const user = useSelector(state => state.oidc.user);
  const { courseId, pnOrEmail } = useParams();
  const [courseState, setCourseState] = useState(null);

  const [participantData, setParticipantData] = useState(null);
  useEffect(() => {
    const _getParticipant = async () => {
      const getRes =
        pnOrEmail.indexOf("@") !== -1
          ? await searchParticipantByEmail(pnOrEmail, user)
          : await searchParticipantByPn(pnOrEmail, user);

      if (getRes.length) {
        setParticipantData(getRes[0]);
      }
    };
    if (user) _getParticipant();
  }, [setParticipantData, pnOrEmail, user]);

  useEffect(() => {
    const _getCourseState = async () => {
      const getRes = await getCourseState(
        participantData.ssoId,
        courseId,
        user
      );
      setCourseState(getRes);
    };
    if (user && participantData) _getCourseState();
  }, [participantData, courseId, user]);

  if (!user && !IS_BYPASS_SSO) return <LoginBridge />;

  if (!courseState || !participantData) return null;

  const { name } = participantData;

  const isSuccess =
    courseState.sections.filter(section => {
      return !section.success;
    }).length === 0;

  return (
    <div className="Participant-course-summary">
      <div className="Participant-course-summary__nav">
        <Link
          className="Participant-course-summary__backlink"
          to="/participantsearch"
        >
          <BackIcon />
          {t("analytics.BACK_TO_PARTICIPANT_SEARCH")}
        </Link>
      </div>
      <SummaryHeader
        preText={name}
        headingText={courseState.courseTitle}
        isSuccess={isSuccess}
      />
      {courseState.sections.map(section => {
        return (
          <ParticipantCourseSummarySection
            key={section.id}
            sectionProgress={section}
          />
        );
      })}
    </div>
  );
}

export default ParticipantCourseSummary;
