import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useQuery as useApolloQuery } from "@apollo/client";

import { GET_SINGLE_TASK } from "apollo/queries";

import { IS_SCORM } from "consts";

import Loading from "components/Loading/Loading";
import TaskHeader from "components/TaskHeader/TaskHeader";
import TaskInitialContent from "components/TaskInitialContent/TaskInitialContent";
import TaskInteractionWrapper from "components/TaskInteractionWrapper/TaskInteractionWrapper";
import TaskOptionFeedback from "components/TaskOptionFeedback/TaskOptionFeedback";

import getTaskType from "utils/getTaskType";

import debugLog from "utils/debugLog";

function AcfTask(props) {
  const { block } = props;

  const [selectedValues, setSelectedValues] = useState([]);
  const [scormLoading, setScormLoading] = useState(false);
  const [scormError, setScormError] = useState(null);
  const [scormData, setScormData] = useState(null);

  // let referenceData;
  // try {
  //   referenceData = JSON.parse(block.attributes.data);
  // } catch (e) {
  //   console.error("Could not parse AcfTask block with id", block.attributes.id);
  // }

  const taskId = block && block.dynamicContent ? block.dynamicContent : null;

  debugLog("AcfTask taskId", taskId);

  const { loading, error, data } = useApolloQuery(GET_SINGLE_TASK, {
    variables: { id: taskId },
    skip: IS_SCORM
  });

  useEffect(() => {
    const _getTask = async function () {
      setScormLoading(true);
      const res = await fetch(`acfTask_${taskId}.json`, {
        method: "GET"
      })
        .then(_res => {
          if (!_res.ok) return {};
          return _res.json();
        })
        .then(json => {
          setScormData(json);
          setScormLoading(false);
        })
        .catch(err => {
          console.error(err);
          setScormError(err);
          setScormLoading(false);
        });
      return res;
    };
    if (IS_SCORM && taskId) _getTask();
  }, []);

  if (loading || scormLoading) return <Loading />;
  if (error) console.error(error);
  if (scormError) console.error(scormError);
  if (
    scormError ||
    error ||
    (!IS_SCORM && (!data || (data && !data.task))) ||
    (IS_SCORM && (!scormData || (scormData && !scormData.task)))
  )
    return null;

  const taskData = IS_SCORM ? scormData.task : data.task;

  const type = getTaskType(taskData);

  const hasSelected = !_.isEmpty(selectedValues);

  return (
    <div className="Fact-block--AcfTask">
      <TaskHeader taskData={taskData} isInFacts={true} />
      {!hasSelected ? <TaskInitialContent taskData={taskData} /> : null}

      <TaskOptionFeedback
        taskData={taskData}
        selectedValues={selectedValues}
        type={type}
      />

      <TaskInteractionWrapper
        taskData={taskData}
        selectedValues={selectedValues}
        selectFn={setSelectedValues}
        type={type}
        isInFacts={true}
        currentTaskStep={-1}
      />
    </div>
  );
}

export default AcfTask;
