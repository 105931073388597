import React, { useState, useEffect, useCallback } from "react";
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import TaskMatchOptionCard from 'components/TaskMatchOptionCard/TaskMatchOptionCard';

import getTaskType from "utils/getTaskType";

import "./TaskInteractionMatch.scss";

const swap = (array, indexA, indexB) => {
  const arr = [...array];
  var temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};

function TaskInteractionMatch(props) {
  const { taskData, selectFn } = props;
  const type = getTaskType(taskData);
  const options =
    taskData.blbGroupTaskOptions[`blbTaskOptionType${type}Options`];
  const [staticCards, setStaticCards] = useState(options);
  const [matchCards, setMatchCards] = useState([]);
  const [isAllPuzzled, setIsAllPuzzled] = useState(false);

  useEffect(() => {
    setIsAllPuzzled(matchCards.filter(card => card.puzzled).length === options.length);
  }, [matchCards, options]);

  useEffect(() => {
    if (isAllPuzzled) {
      selectFn(
        matchCards.map(({blbTaskMatchoptionId, blbTaskMatchoptionLabel}, i) => {   
          return {
            optionId: blbTaskMatchoptionId,
            optionLabel: blbTaskMatchoptionLabel,
            extraData: staticCards[i].blbTaskTypeMatchOptionCorrectMatchoptionId
          };
        })
      );
    } else {
      selectFn([]);
    }
  }, [matchCards, staticCards, selectFn, isAllPuzzled]);

  // Initial state set
  useEffect(() => {
    setMatchCards(
      taskData.blbGroupTaskOptions.blbTaskMatchoptions.map(opt => {
        return { ...opt, puzzled: false };
      })
    );
  }, [taskData.id, taskData]);

  const moveCard = useCallback(
    (originalIndex, targetIndex, itemId) => {

      const wasDroppedOnItself = originalIndex === targetIndex;

      // Swap place of the card that has been dragged with the card on the target index
      const swapped = swap(matchCards, originalIndex, targetIndex);

      // Mark as "puzzled" to change style and state
      const puzzled = swapped.map((card) => {
        if(card.blbTaskMatchoptionId !== itemId) return card;

        // Just toggle
        if(wasDroppedOnItself) {
          return {...card, puzzled: !card.puzzled}
        }

        // It was moved to another index - set to true
        return {...card, puzzled: true}
      });

      setMatchCards(puzzled);
    },
    [matchCards]
  );

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <div className="Task-interaction-match">
          <div className="Task-interaction-match__wrapper">
            {staticCards.map((card, index) => {
              const matchedCard = matchCards.find((c, i) => {
                return i === index
              });
              const puzzled = matchedCard?.puzzled;
              const cls = `Task-interaction-match__static-card ${puzzled ? 
                'Task-interaction-match__static-card--puzzled' : ''}`
              return (
                <div className={cls} key={card.blbTaskTypeMatchOptionId}>
                  <span className="Task-interaction-match__static-card__text">{card.blbTaskTypeMatchOptionLabel}</span>
                </div>
              );
            })}
          </div>
          <div className="Task-interaction-match__wrapper">
            {matchCards.map((card, index) => {
              return (
                <TaskMatchOptionCard
                  key={card.blbTaskMatchoptionId}
                  index={index}
                  id={card.blbTaskMatchoptionId}
                  text={card.blbTaskMatchoptionLabel}
                  moveCard={moveCard}
                  puzzled={card.puzzled}
                />
              );
            })}
          </div>
        </div>
      </DndProvider>

  );
}

export default TaskInteractionMatch;
