import Rollbar from "rollbar";
import rollbarConfig from "rollbarConfig";
import getUserConfigForRollbar from "utils/getUserConfigForRollbar";

// Custom rollbar event for logging
// various errors that arise in communication
// with the Tracker API.

async function sendRollbar(response, reqInput, heading, userManager) {
  const rollbar = new Rollbar(rollbarConfig);

  let user;
  try {
    user = await userManager.getUser();
  } catch (e) {
    //
  }

  let errorText;
  try {
    errorText = await response.text();
  } catch (e) {
    //
  }

  // TODO would be nice to re-use the rollbar
  // instance from index.js here... Question
  // is where to put it
  rollbar.configure(getUserConfigForRollbar(user));

  const meta = {
    url: response.url,
    status: response.status,
    errorText,
    reqInput
  };
  rollbar.error(heading, meta);
}

export default sendRollbar;
