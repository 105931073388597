// Course
export const setFullCourseState = data => ({
  type: "SET_FULL_COURSE_STATE",
  data
});
export const setSelectedSection = id => ({
  type: "SET_SELECTED_SECTION",
  data: id
});
export const setSelectedChapter = id => ({
  type: "SET_SELECTED_CHAPTER",
  data: id
});
export const setSelectedTaskGroup = id => ({
  type: "SET_SELECTED_TASK_GROUP",
  data: id
});
export const setSectionToVerify = sectionId => ({
  type: "SET_SECTION_TO_VERIFY",
  data: sectionId
});
export const setSectionToResultView = sectionId => ({
  type: "SET_SECTION_TO_RESULT_VIEW",
  data: sectionId
});

export const resetNavigation = () => ({
  type: "RESET_NAVIGATION"
});

// Layout
export const toggleChapterNav = () => ({
  type: "TOGGLE_CHAPTER_NAV"
});

export const expandChapterNav = () => ({
  type: "EXPAND_CHAPTER_NAV"
});

export const collapseChapterNav = () => ({
  type: "COLLAPSE_CHAPTER_NAV"
});

export const openTaskOverlay = () => ({
  type: "OPEN_TASK_OVERLAY"
});
export const closeTaskOverlay = () => ({
  type: "CLOSE_TASK_OVERLAY"
});
export const setFactContentModalToView = data => ({
  type: "SET_FACT_CONTENT_MODAL_TO_VIEW",
  data
});
export const setPrefaceExpanded = bool => ({
  type: "SET_PREFACE_EXPANDED",
  data: bool
});

export const openChapterCompletedOverlay = () => ({
  type: "OPEN_CHAPTER_COMPLETED_OVERLAY"
});
export const closeChapterCompletedOverlay = () => ({
  type: "CLOSE_CHAPTER_COMPLETED_OVERLAY"
});
export const setWaitedLongEnough = () => ({
  type: "SET_WAITED_LONG_ENOUGH"
});
export const setCurrentSoundIdPlaying = soundId => ({
  type: "SET_CURRENT_SOUND_ID_PLAYING",
  data: soundId
});
export const setTheme = theme => ({
  type: "SET_THEME",
  data: theme
});
export const setLanguageWasSetFromCourse = () => ({
  type: "SET_LANGUAGE_WAS_SET_FROM_COURSE"
});
export const setAvailableLanguages = data => ({
  type: "SET_AVAILABLE_LANGUAGES",
  data
});
export const setCurrentLanguage = lang => ({
  type: "SET_CURRENT_LANGUAGE",
  data: lang
});

// Session
export const setAccessStatus = data => ({
  type: "SET_ACCESS_STATUS",
  data
});
export const notifyAccessStatusChange = () => ({
  type: "NOTIFY_ACCESS_STATUS_CHANGE"
});
export const setUserLogout = data => ({
  type: "SET_USER_LOGOUT",
  data
});
export const setReferrer = data => ({
  type: "SET_REFERRER",
  data
});

// Analytics
export const setSectionDataToAnalyticsResultView = sectionProgress => ({
  type: "SET_SECTION_DATA_TO_ANALYTICS_RESULT_VIEW",
  data: sectionProgress
});

export const setPnInput = pnInput => ({
  type: "SET_PN_INPUT",
  data: pnInput
});
export const setEmailInput = emailInput => ({
  type: "SET_EMAIL_INPUT",
  data: emailInput
});
export const setSearchResults = results => ({
  type: "SET_SEARCH_RESULTS",
  data: results
});
export const setActiveSearchTab = tabId => ({
  type: "SET_ACTIVE_SEARCH_TAB",
  data: tabId
});

// Scorm
export const setScormCourseDataCache = data => ({
  type: "SET_SCORM_COURSE_DATA_CACHE",
  data
});
export const setScormCourseDataLoading = data => ({
  type: "SET_SCORM_COURSE_DATA_LOADING",
  data
});