import useCurrentCourse from "hooks/useCurrentCourse";
import useQuery from "hooks/useQuery";

import { IS_FORCE_PREVIEW } from "consts";

import randomIntFromInterval from "utils/randomIntFromInterval";
import randomLetters from "utils/randomLetters";

import debugLog from "utils/debugLog";

// If we're in preview mode, we'll fake a eduadminId with some
// random letters, numbers and timestamp to get a unique session.
// Declared outside of hook to persist it until reload.
const randomPreviewId = `preview-${randomLetters(4)}${randomIntFromInterval(
  1,
  9999999
)}${Date.now()}`;

function useCurrentEduadminId() {
  let r = "";
  const { data } = useCurrentCourse();
  const query = useQuery();

  if (!data || (data && !data.course)) return "";

  const previewQueryParam = query.get("preview");

  if (previewQueryParam === "true" || IS_FORCE_PREVIEW) {
    r = randomPreviewId;
  } else {
    r =
      data.course.blbGroupCourseEduadminId &&
      data.course.blbGroupCourseEduadminId.blbCourseEduadminId
        ? data.course.blbGroupCourseEduadminId.blbCourseEduadminId
        : "";
  }

  debugLog("useCurrentEduadminId", r);

  return r;
}

export default useCurrentEduadminId;
