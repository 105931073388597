import React from "react";

import ParagraphFromHtml from "components/ParagraphFromHtml/ParagraphFromHtml";

function CoreParagraph(props) {
  const { block } = props;
  const { attributes } = block;
  const content = block.originalContent;

  const cls =
    `Fact-block--CoreParagraph` +
    (attributes.align
      ? ` Fact-block--CoreParagraph--align${attributes.align}`
      : "");
  return <ParagraphFromHtml content={content} className={cls} />;
}

export default CoreParagraph;
