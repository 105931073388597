import React, { useState, useCallback, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withScorm } from "react-scorm-provider";

import {
  setSelectedSection,
  setSectionToVerify,
  setFullCourseState
} from "actions";

import useSectionProgress from "hooks/useSectionProgress";

import { ENTITY_STATUSES, IS_SCORM } from "consts";

import ButtonLoading from "components/ButtonLoading/ButtonLoading";
import CourseSectionProgress from "components/CourseSectionProgress/CourseSectionProgress";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import useCurrentEduadminId from "hooks/useCurrentEduadminId";

import "./CourseSectionSelectItem.scss";

import sectionHasTasks from "utils/sectionHasTasks";

import { postSectionRestarted } from "tracker-api/statements";
import { getCourseState } from "tracker-api/sessions";

function CourseSectionSelectItem(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { section, isCourseVerifiedSuccess } = props;
  const sectionProgress = useSectionProgress(section);
  const eduadminId = useCurrentEduadminId();
  const user = useSelector(getUserWithAccessStatus);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sco = useMemo(() => props.sco, []);

  const cls =
    "Course-section-select-item Course-section-select-item--status" +
    sectionProgress.status;

  const statusLabels = [
    t("START"),
    t("CONTINUE_WHERE_YOU_LEFT_OFF"),
    t("VERIFY_X_CHAPTERS", { chaptersCount: sectionProgress.chaptersCount }),
    t("TRY_AGAIN")
  ];

  const action = useCallback(() => {
    async function _action() {
      if (sectionProgress.status === ENTITY_STATUSES.COMPLETE) {
        // If section is complete but not verified,
        // open verify overlay. Otherwise just navigate.
        dispatch(setSectionToVerify(section.blbCourseSectionId));
      } else if (sectionProgress.status === ENTITY_STATUSES.VERIFIED_FAILED) {
        setIsSubmitLoading(true);
        await postSectionRestarted(
          section.blbCourseSectionId,
          eduadminId,
          user,
          sco
        );
        const getRes = await getCourseState(eduadminId, user, true, sco);
        setIsSubmitLoading(false);
        dispatch(setFullCourseState(getRes));
        dispatch(setSelectedSection(section.blbCourseSectionId));
      } else {
        dispatch(setSelectedSection(section.blbCourseSectionId));
      }
    }
    _action();
  }, [
    setIsSubmitLoading,
    sectionProgress,
    section,
    eduadminId,
    dispatch,
    user,
    sco
  ]);

  return (
    <li className={cls}>
      <h2 className="Course-section-select-item__heading">
        {section.blbCourseSectionTitle}
      </h2>

      <div className="Course-section-select-item__progress">
        <CourseSectionProgress
          status={sectionProgress.status}
          completedChaptersCount={sectionProgress.completedChaptersCount}
          chaptersCount={sectionProgress.chaptersCount}
          hideText={!sectionHasTasks(section)}
        />
      </div>

      {sectionProgress.status < ENTITY_STATUSES.COMPLETE ? (
        <p className="Course-section-select-item__text">
          {section.blbCourseSectionDescription}
        </p>
      ) : null}
      {sectionProgress.status === ENTITY_STATUSES.VERIFIED_SUCCESS ? (
        <p className="Course-section-select-item__text">
          <Trans
            i18nKey={
              isCourseVerifiedSuccess
                ? "REPEAT_FACTS_COURSE_SUCCESS_LINK_TEXT"
                : "REPEAT_FACTS_COURSE_PROGRESS_LINK_TEXT"
            }
          >
            <a
              href="#"
              className="Course-section-select-item__link"
              onClick={e => {
                e.preventDefault();
                action();
              }}
            >
              x
            </a>
          </Trans>
        </p>
      ) : null}

      {sectionProgress.status < ENTITY_STATUSES.VERIFIED_SUCCESS ? (
        <button
          disabled={isSubmitLoading}
          type="button"
          className="Course-section-select-item__button"
          onClick={action}
        >
          {statusLabels[sectionProgress.status - 1]}
          {isSubmitLoading ? <ButtonLoading /> : null}
        </button>
      ) : null}
    </li>
  );
}

// TODO proptypes

const Comp = IS_SCORM
  ? withScorm()(CourseSectionSelectItem)
  : CourseSectionSelectItem;

export default Comp;
