// This stores course data
// when app is running in SCORM mode

const scorm = (
  state = {
    scormCourseDataCache: null,
    scormCourseDataLoading: false
  },
  action
) => {
  switch (action.type) {
    case "SET_SCORM_COURSE_DATA_CACHE":
      return {
        ...state,
        scormCourseDataCache: action.data
      };
    case "SET_SCORM_COURSE_DATA_LOADING":
      return {
        ...state,
        scormCourseDataLoading: action.data
      }

    default:
      return state;
  }
};

export default scorm;
