import envConfig from "environment.json";

export const GQL_SECTIONS_NEST =
  "course.blbGroupCourseSections.blbCourseSections";

export const TASK_OPTION_TYPES = {
  TEXT: "Text",
  IMAGE: "Image",
  ORDER: "Order",
  SLIDER: "Slider",
  MATCH: "Match"
};

export const ENTITY_STATUSES = {
  UNSTARTED: 1,
  INCOMPLETE: 2,
  COMPLETE: 3,
  VERIFIED_FAILED: 4,
  VERIFIED_SUCCESS: 5
};

export const LARGE_DESKTOP_LAYOUT_BREAKPOINT = 1280;
export const DESKTOP_LAYOUT_BREAKPOINT = 1024;
export const TABLET_LAYOUT_BREAKPOINT = 768;

export const MIN_SLIDER_DEFAULT_VALUE = 0;
export const MAX_SLIDER_DEFAULT_VALUE = 100;
export const DEFAULT_SLIDER_VALUE = 50;

export const MIN_STEP_GRADING_SLIDER_DEFAULT_VALUE = 1;
export const MAX_STEP_GRADING_SLIDER_DEFAULT_VALUE = 5;

export const DEFAULT_TRANSITION_MS = 250;

export const BUC_URL = "https://buc.se";
export const MY_PAGES_URL = `${BUC_URL}/mina-sidor`;
export const SUPPORT_EMAIL = "support@buc.se";

export const FASTTRACK_DIPLOMA_URL = envConfig.FASTTRACK_DIPLOMA_URL;


// TODO these should be moved to some other file,
// not really consts
export const IS_DEBUG = parseInt(envConfig.DEBUG) === 1;
export const IS_BYPASS_SSO = parseInt(envConfig.BYPASS_SSO) === 1;
export const IS_FORCE_PREVIEW = parseInt(envConfig.FORCE_PREVIEW) === 1;
export const IS_SCORM = parseInt(envConfig.SCORM) === 1;

export const BLOCK_GROUP_NAME = "buc-lms-builder/group";

export const REDIRECT_TO_COOKIE_NAME = "redirectTo";
export const REFERRER_COOKIE_NAME = "referrer";

export const OVERLAY_OPEN_BODY_CLASSNAME = "overlayOpen";

export const GENERAL_UNAUTHENTICATED_ERROR_KEY = "UNAUTHENTICATED_TEXT";
export const GENERAL_UNAUTHORIZED_ERROR_KEY = "UNAUTHORIZED_TEXT";


export const BYGGFORETAGEN_C_ACTION_PRIMARY = "fffc4d";
export const BUC_C_ACTION_PRIMARY = "fbf142";
export const BYGGFORETAGEN_C_LINK_INVERSE = "ff634f";
export const BUC_C_LINK_INVERSE = "ff634f";
export const BYGGFORETAGEN_C_BUTTON_DEFAULT_TEXT = "3f4a4f";
export const BUC_C_BUTTON_DEFAULT_TEXT = "454c41";
