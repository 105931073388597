import { combineReducers } from "redux";
import { reducer as oidc } from "redux-oidc";
import course from "./course";
import layout from "./layout";
import session from "./session";
import analytics from "./analytics";
import scorm from "./scorm";

export default combineReducers({
  course,
  layout,
  oidc,
  session,
  analytics,
  scorm
});
