import ApolloClient from "apollo-boost";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";

import introspectionQueryResultData from "../schema.json";

import envConfig from "environment.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });
const uri = envConfig.COURSES_GRAPHQL_URI;

const client = new ApolloClient({
  uri,
  cache,
});

export default client;
