import React from "react";

import stripHtml from "utils/stripHtml";

import VimeoVideo from "components/VimeoVideo/VimeoVideo";

function CoreEmbedVimeo(props) {
  const { block } = props;
  const vimeoUrl = stripHtml(block.originalContent);
  if (!vimeoUrl) return null;

  return (
    <div className="Fact-block--CoreEmbedVimeo">
      <VimeoVideo vimeoUrl={vimeoUrl} />
    </div>
  );
}

export default CoreEmbedVimeo;
