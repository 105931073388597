import React from "react";

import { ReactComponent as IconCheck } from "images/icons/check.svg";
import "./TaskOption.scss";

import { IS_DEBUG } from "consts";

import PropTypes from "prop-types";

function TaskOption(props) {
  const cls =
    "Task-option" +
    (props.isSelected ? " Task-option--isselected" : "") +
    (props.imageSrc ? "  Task-option--hasimg" : "") +
    (props.dc && IS_DEBUG ? " Task-option--dc" : "");
  return (
    <label className={cls}>
      {props.imageSrc ? (
        <div className="Task-option__image">
          <img
            src={props.imageSrc}
            alt={props.imageAlt ? props.imageAlt : ""}
          />
        </div>
      ) : null}
      <span className="Task-option__text">{props.label}</span>
      {props.isSelected ? (
        <span className="Task-option__icon">
          <IconCheck />
        </span>
      ) : null}
      <input
        className="Task-option__element"
        type={
          props.isMultiple || props.allowDeselectOfSingleAnswer
            ? "checkbox"
            : "radio"
        }
        value={props.value}
        onChange={props.selectFn.bind(null, props.value)}
        checked={props.isSelected}
      />
    </label>
  );
}

TaskOption.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  isMultiple: PropTypes.bool,
  selectFn: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  dc: PropTypes.bool, // dc = debug correct
};

export default TaskOption;
