import React from "react";

import { withScorm } from "react-scorm-provider";

import { getMode, getCredit } from "scorm/api";

function Scormtest({ sco }) {
  return (
    <div className="Scormtest">
      <h3>Scormtest</h3>
      <p>apiConnected: {sco.apiConnected ? "yes" : "no"}</p>
      <p>completionStatus: {sco.completionStatus}</p>
      <p>learnerName: {sco.learnerName}</p>
      <p>scormVersion: {sco.scormVersion}</p>
      <p>mode: {getMode(sco)}</p>
      <p>credit: {getCredit(sco)}</p>
    </div>
  );
}

export default withScorm()(Scormtest);
