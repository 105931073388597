import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./Home.scss";

import Scormtest from "./Scormtest";

import { Redirect } from "react-router-dom";

import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import getUserManager from "userManager";

import { IS_DEBUG, BUC_URL, IS_SCORM } from "consts";

function Home() {
  const userManager = getUserManager();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formval, setFormval] = useState("");

  const user = useSelector(getUserWithAccessStatus);

  // Comment this to access this page in Scorm mode
  if (window.SCORM_COURSE_SLUG) {
    return <Redirect to={`/course/${window.SCORM_COURSE_SLUG}`} />;
  }

  if (!IS_DEBUG) {
    if (window && !IS_SCORM) {
      window.location = BUC_URL;
    }

    return null;
  }

  if (isSubmitted && formval) {
    return <Redirect to={`/course/${formval}`} />;
  }
  const submit = e => {
    e.preventDefault();
    setIsSubmitted(true);
  };
  const login = () => {
    userManager.signinRedirect();
  };
  const logout = () => {
    userManager.signoutRedirect();
  };
  const getUser = () => {
    userManager.getUser().then(res => {
      console.log("User from userManager", res);
    });
  };
  const querySessionStatus = () => {
    userManager.querySessionStatus().then(res => {
      console.log("querySessionStatus", res);
    }).catch(e => {
      console.error("querySessionStatus err", e);
    });
  };
  const removeUser = () => {
    userManager.removeUser().then(res => {
      console.log("rem from userManager", res);
    });
  };
  return (
    <div className="Home">
      <div className="test">Home</div>
      <div>
        <form onSubmit={submit}>
          <label>
            Gå till kurs (slug):
            <input
              type="text"
              value={formval}
              onChange={e => setFormval(e.target.value)}
            />
          </label>
          <button type="Submit">Kör</button>
        </form>
        <h2>Oidc</h2>
        <button type="button" onClick={login}>
          Oidc login
        </button>
        <button type="button" onClick={logout}>
          Oidc logut
        </button>
        <button type="button" onClick={getUser}>
          Get user from userManager
        </button>
        <button type="button" onClick={querySessionStatus}>
          querySessionStatus
        </button>
        <button type="button" onClick={removeUser}>
          Remove user from userManager
        </button>
        <pre>{`${JSON.stringify(user, null, 2)}`}</pre>
        {IS_SCORM ? <Scormtest /> : null}
      </div>
    </div>
  );
}

export default Home;
