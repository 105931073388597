import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./TaskInteractionOrder.scss";

import { IS_DEBUG } from "consts";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import debugLog from "utils/debugLog";

let animateTimeout;

function TaskInteractionOrder(props) {
  const { t } = useTranslation();
  const { taskData, selectFn } = props;
  const [items, setItems] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  // Initial state set
  useEffect(() => {
    setItems(
      taskData.blbGroupTaskOptions.blbTaskOptionTypeOrderOptions.map(opt => {
        return { ...opt, animate: false };
      })
    );
  }, [taskData.id, taskData]);

  // Pass upwards after change. Join the array to a string to
  // use as unique check
  const stringedItems = items
    .map(item => item.blbTaskTypeOrderOptionCorrectPosition)
    .join("");
  useEffect(() => {
    if (isDirty) {
      selectFn(
        items.map(item => {
          return {
            optionId: item.blbTaskTypeOrderOptionId,
            extraData: item.blbTaskTypeOrderOptionCorrectPosition
          };
        })
      );
    }
  }, [stringedItems, items, selectFn, isDirty]);

  const move = (itemIndex, isUp) => {
    if (isUp && itemIndex === 0) return;
    if (!isUp && itemIndex === items.length - 1) return;
    clearTimeout(animateTimeout);
    const newArr = items.map(item => {
      return { ...item, animate: false };
    });
    const newIndex = isUp ? itemIndex - 1 : itemIndex + 1;
    const firstItem = items[itemIndex];
    newArr[itemIndex] = items[newIndex];
    newArr[newIndex] = { ...firstItem, animate: true };
    animateTimeout = setTimeout(() => {
      debugLog("running animate timeout");
      setItems(
        newArr.map(item => {
          return { ...item, animate: false };
        })
      );
    }, 1000);
    setItems(newArr);
    setIsDirty(true);
  };

  return (
    <div className="Task-interaction-order">
      <ul className="Task-interaction-order__list">
        {items.map((item, i) => {
          const itemcls =
            "Task-interaction-order__listitem" +
            (i === items.length - 1
              ? " Task-interaction-order__listitem--isLast"
              : "") +
            (item.animate ? " Task-interaction-order__listitem--animate" : "");
          return (
            <li className={itemcls} key={item.blbTaskTypeOrderOptionId}>
              <p className="Task-interaction-order__text">
                <span className="Task-interaction-order__ordernum">
                  {i + 1}
                </span>
                {item.blbTaskTypeOrderOptionLabel}
              </p>
              <div className="Task-interaction-order__btnwrap">
                <button
                  className="Task-interaction-order__downbtn"
                  type="button"
                  onClick={move.bind(null, i, false)}
                  aria-label={t("MOVE_DOWN")}
                >
                  <BackIcon />
                </button>
                <button
                  className="Task-interaction-order__upbtn"
                  type="button"
                  onClick={move.bind(null, i, true)}
                  aria-label={t("MOVE_UP")}
                >
                  <BackIcon />
                </button>
              </div>
              {IS_DEBUG ? (
                <span className="Task-interaction-order__dc">
                  {item.blbTaskTypeOrderOptionCorrectPosition}
                </span>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TaskInteractionOrder;
