import React from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import "./TaskMatchOptionCard.scss";

function TaskMatchOptionCard({ id, text, index, moveCard, puzzled }) {

  const ref = useRef(null);
  const ItemTypes = {
    CARD: 'card',
  }
  // drop
  const [{ highlighted, hovered, handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        hovered: monitor.isOver(),
        highlighted: monitor.canDrop()
      };
    },
    drop(item, monitor) {
      // runs when card is dropped

      if (!ref.current) {
        return;
      }

      // current index of the dragging card
      const originalIndex = item.index;

      // target index of where card has been dropped
      const targetIndex = index;
      moveCard(originalIndex, targetIndex, item.id);
    }
  });

  // drag
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  
  const isActive = highlighted && hovered;

  const cardCls = `
    Task-match-option-card
    ${puzzled ? 'Task-match-option-card--puzzled' : ''}
    ${isDragging ? 'Task-match-option-card--dragging' : ''}
    ${isActive ? 'Task-match-option-card--active' : ''}
    ${highlighted ? 'Task-match-option-card--drop' : ''}
    ${hovered ? 'Task-match-option-card--hovered' : ''}
  ` 
  drag(drop(ref));

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={cardCls}
    >
      <span className="Task-match-option-card__text">{text}</span>
    </div>
  );
};

export default TaskMatchOptionCard;
