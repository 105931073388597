import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector
} from "react-redux";
import { OidcProvider, loadUser } from "redux-oidc";
import { createStore } from "redux";
import { CookiesProvider } from "react-cookie";
import Rollbar from "rollbar";
import ScormProvider from "react-scorm-provider";
import rollbarConfig from "./rollbarConfig";

import getUserManager from "./userManager";

// import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";

import rootReducer from "reducers";
import { setSelectedTaskGroup, setFactContentModalToView, setReferrer } from "actions";

import apolloClient from "apollo/client";

//

// Pages
import Course from "pages/Course/Course";
import CourseSummary from "pages/CourseSummary/CourseSummary";
import Home from "pages/Home/Home";
import ResourcePage from "pages/ResourcePage/ResourcePage";

// Components //

import CourseNotFound from "components/CourseNotFound/CourseNotFound";
import ChapterCompletedOverlay from "components/ChapterCompletedOverlay/ChapterCompletedOverlay";
import Overlay from "components/Overlay/Overlay";
import SectionVerifierOverlay from "components/SectionVerifierOverlay/SectionVerifierOverlay";
import SectionResultViewOverlay from "components/SectionResultViewOverlay/SectionResultViewOverlay";
import MainFooter from "components/MainFooter/MainFooter";
import FactContentModalOverlay from "components/FactContentModalOverlay/FactContentModalOverlay";
import ScormApiChecker from "components/ScormApiChecker/ScormApiChecker";

import Callback from "pages/Callback/Callback";
import SilentRenew from "pages/SilentRenew/SilentRenew";
import Task from "pages/Task/Task";
import ParticipantSearch from "pages/ParticipantSearch/ParticipantSearch";
import ParticipantCourseSummary from "pages/ParticipantCourseSummary/ParticipantCourseSummary";
import Maintenance from "pages/Maintenance/Maintenance";

import useIsMaintenanceMode from "hooks/useIsMaintenanceMode";
import getUserWithAccessStatus from "selectors/getUserWithAccessStatus";

import getUserConfigForRollbar from "utils/getUserConfigForRollbar";
import debugLog from "utils/debugLog";

import { isLoggedIn } from "tracker-api/sessions";

import { IS_BYPASS_SSO, IS_SCORM, IS_DEBUG, REFERRER_COOKIE_NAME } from "consts";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const Router = IS_SCORM ? HashRouter : BrowserRouter;

// // // //

const MaybeScorm = ({ children }) => {
  if (IS_SCORM) {
    return (
      <ScormProvider version={window.SCORM_VERSION || "1.2"} debug={IS_DEBUG}>
        <ScormApiChecker>{children}</ScormApiChecker>
      </ScormProvider>
    );
  } else {
    return children;
  }
};

// This component registers all
// instances of overlays that
// we need for the routes that
// triggers them
const OverlaySetup = props => {
  const dispatch = useDispatch();
  const chapterCompletedOverlayOpen = useSelector(
    state => state.layout.chapterCompletedOverlayOpen
  );
  const taskOverlayOpen = useSelector(state => state.layout.taskOverlayOpen);

  const sectionToVerify = useSelector(state => state.course.sectionToVerify);
  const sectionToResultView = useSelector(
    state => state.course.sectionToResultView
  );

  const selectedTaskGroup = useSelector(
    state => state.course.selectedTaskGroup
  );

  const analyticsSectionProgress = useSelector(
    state => state.analytics.sectionProgress
  );

  const factContentModalToView = useSelector(
    state => state.layout.factContentModalToView
  );

  // Remove all overlays if user is not logged in,
  // so that the LoginBridge is visible.
  const user = useSelector(state => state.oidc.user);

  const publicOverlays = () => (
    <>
      {/* Simple-variant modals from facts sections */}
      <Overlay
        show={!!factContentModalToView}
        isSimple={true}
        onOutsideClick={() => {
          dispatch(setFactContentModalToView(null));
        }}
        onExited={() => {
          dispatch(setFactContentModalToView(null));
        }}
      >
        <FactContentModalOverlay />
      </Overlay>
    </>
  );

  if (props.publicOnly) return publicOverlays();

  if (!user && !IS_BYPASS_SSO) return null;

  return (
    <div>
      {publicOverlays()}
      
      {/* Task overlay */}
      <Overlay
        show={taskOverlayOpen}
        onExited={() => {
          // Remove seleted task group once overlay
          // is done animating
          dispatch(setSelectedTaskGroup(null));
        }}
      >
        <Task key={selectedTaskGroup} />
      </Overlay>

      {/* Completed chapter overlay */}
      <Overlay show={chapterCompletedOverlayOpen}>
        <ChapterCompletedOverlay />
      </Overlay>

      {/* Section verify overlay */}
      <Overlay show={!!sectionToVerify}>
        <SectionVerifierOverlay />
      </Overlay>

      {/* Section result view overlay */}
      <Overlay show={!!sectionToResultView || !!analyticsSectionProgress}>
        <SectionResultViewOverlay />
      </Overlay>

      
    </div>
  );
};

const ThemeApplier = ({ children }) => {
  const theme = useSelector(state => state.layout.theme);

  useEffect(() => {
    const THEME_PREFIX = "theme-";
    const themeCls = `${THEME_PREFIX}${theme}`;
    if (theme && document && document.body) {
      const newClassattr = document.body.className.split(" ").filter(cls => {
        return cls && cls.substring(0, 6) !== THEME_PREFIX;
      });
      document.body.className = newClassattr + " " + themeCls;
    }
  }, [theme]);
  return children;
};

const CheckUser = ({ children, userManager }) => {
  // Redux didn't have time to load the user here,
  // so checking directly on userManager instead
  // in each effect below

  const [triedSilentSignin, setTriedSilentSignin] = useState(false);

  // Check the API that the user info
  // we have locally (populated in store
  // by loadUser above) is still valid.
  // If it's not, we clear the userManager
  // which will clear the store and trigger
  // affected pages to show login prompt.
  //
  // This is a workarond for mainly Safari,
  // since it doesn't allow cross-communication
  // through iframes that oidc-client uses.
  useEffect(() => {
    const _isLoggedIn = async () => {
      const user = await userManager.getUser();
      if (user) {
        let getRes;
        try {
          getRes = await isLoggedIn(user);
        } catch (e) {}
        if (!getRes) {
          userManager.removeUser().then(() => {
            debugLog("Api says user is not logged in, clearing local user");
          });
        }
      }
    };
    if (!userManager.settings.monitorSession && !IS_BYPASS_SSO) _isLoggedIn();
  }, [userManager]);

  // If monitorSession is available, we assume that
  // we can use silent signins as well. Only try it once
  // if the userManager doesn't have a local user.
  // If it does have a local user, monitorSession
  // will take care of it if it would not be valid.
  useEffect(() => {
    const _signinSilent = async function () {
      const user = await userManager.getUser();
      if (!user) {
        userManager
          .signinSilent()
          .then(function (newUser) {
            debugLog("Successfully signed in user silently", newUser);
            setTriedSilentSignin(true);
          })
          .catch(function (e) {
            debugLog("Tried and failed to sign in user silently", e);
            setTriedSilentSignin(true);
          });
      }
    };
    if (
      userManager.settings.monitorSession &&
      !triedSilentSignin &&
      !IS_BYPASS_SSO
    )
      _signinSilent();
  }, [triedSilentSignin, userManager]);

  return children;
};

const RollbarVariableConfig = ({ rollbar, children }) => {
  // Needs to be inside ReduxProvider
  const user = useSelector(getUserWithAccessStatus);

  useEffect(() => {
    debugLog("Setting Rollbar user config", user);
    rollbar.configure(getUserConfigForRollbar(user));
  }, [user, rollbar]);

  return children;
};


const ApplySupplementaryParamData = ({ children, userManager }) => {
  const query = new URLSearchParams(window.location.search);
  const referrerQueryParam = query.get("referrer");
  const [didInitiateSignoutoutListener, setDidInitiateSignoutoutListener] = useState(false);

  const dispatch = useDispatch();

  // if we have a "referrer" param, set it to the
  // store and remove it from the url to prevent
  // user from saving the url etc. This is mainly to make
  // LoginBridge make correct redirect in a fasttrack course.
  useEffect(() => {
    if(referrerQueryParam) {
      debugLog("Setting referrer", referrerQueryParam);
      dispatch(setReferrer(referrerQueryParam));
      const path = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, path);
    }
  }, [referrerQueryParam, dispatch]);

  // Handle case where user may be signed out of
  // SSO from another location. Clear the referrer
  // in store in order to instruct LoginBridge
  // to redirect back to fasttrack login.
  useEffect(() => {
    if(userManager && userManager.settings.monitorSession && !didInitiateSignoutoutListener) {
      debugLog("Initiating addUserSignedOut listener");
      userManager.events.addUserSignedOut((a) => {
        debugLog("User was signed out, clearing referrer in store.");
        dispatch(setReferrer(""));
      });
      setDidInitiateSignoutoutListener(true);
    }
  }, [userManager, didInitiateSignoutoutListener, setDidInitiateSignoutoutListener, dispatch]);

  return children;
}

const App = () => {
  const userManager = getUserManager();
  const mainCls = "Main";
  
  const [didInitialUserLoad, setDidInitialUserLoad] = useState(false);
  const [rollbar, setRollbar] = useState(new Rollbar(rollbarConfig));

  const isMaintenanceMode = useIsMaintenanceMode();

  // Preload the store with user stuff
  // if already logged in
  useEffect(() => {
    if (!didInitialUserLoad) {
      if (!IS_BYPASS_SSO) {
        loadUser(store, userManager).then(() => {
          setDidInitialUserLoad(true);
        }).catch(() => {
          setDidInitialUserLoad(true);
        });
      } else {
        setDidInitialUserLoad(true);
      }
    }
  }, [didInitialUserLoad, userManager]);

  if (!didInitialUserLoad) return null;

  return (
    <ApolloProvider client={apolloClient}>
      <ReduxProvider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <CookiesProvider>
            <ApplySupplementaryParamData userManager={userManager}>
              <ThemeApplier>
                <MaybeScorm>
                  <RollbarVariableConfig rollbar={rollbar}>
                    {isMaintenanceMode ? (
                      <main className={mainCls}>
                        <Maintenance />
                      </main>
                    ) : (
                      <Router>
                        {/* <MainHeader /> */}

                        <Switch>
                          <Route path="/course/:courseSlug/summary">
                            <CheckUser userManager={userManager}>
                              <main className={mainCls}>
                                <CourseSummary />
                                <OverlaySetup />
                              </main>
                            </CheckUser>
                            {/* <MainFooter /> */}
                          </Route>
                          <Route path="/course/:courseSlug">
                            <CheckUser userManager={userManager}>
                              <main className={mainCls}>
                                <Course />
                                <OverlaySetup />
                              </main>
                              <MainFooter />
                            </CheckUser>
                          </Route>
                          <Route path="/course">
                            <CheckUser userManager={userManager}>
                              <main className={mainCls}>
                                <CourseNotFound />
                              </main>
                            </CheckUser>
                          </Route>
                          <Route path="/participantsearch/:pnOrEmail/:courseId">
                            <CheckUser userManager={userManager}>
                              <main className={mainCls}>
                                <ParticipantCourseSummary />
                                <OverlaySetup isInAnalytics={true} />
                              </main>
                            </CheckUser>
                          </Route>
                          <Route path="/participantsearch">
                            <CheckUser userManager={userManager}>
                              <main className={mainCls}>
                                <ParticipantSearch />
                              </main>
                            </CheckUser>
                          </Route>
                          <Route path="/analytics">
                            <Redirect to={"/participantsearch"} />
                          </Route>
                          <Route path="/r/:pageSlug">
                            <main className={mainCls}>
                              <ResourcePage />
                              <OverlaySetup publicOnly={true} />
                            </main>
                          </Route>
                          <Route path="/callback" component={Callback} />
                          <Route path="/silentrenew" component={SilentRenew} />
                          <Route path="/">
                            <CheckUser userManager={userManager}>
                              <main className={mainCls}>
                                <Home />
                              </main>
                            </CheckUser>
                          </Route>
                        </Switch>
                      </Router>
                    )}
                  </RollbarVariableConfig>
                </MaybeScorm>
              </ThemeApplier>
            </ApplySupplementaryParamData>
          </CookiesProvider>
        </OidcProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
};

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

//
//
//
//
//
